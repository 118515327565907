const Data=[
    {
    id:1,
    title:"Nepal Hoilday Tour",
    disc:" Embark on a journey of a lifetime with our Nepal holiday tour. Experience adventure, culture, and natural beauty in this stunning destination. Let us plan your dream trip today.",
    image:"./photos/tour/holiday.jpg"
},
    {
    id:2,
    title:"Cultural Tour In Nepal",
    disc:"  Discover the fascinating culture of Nepal with our cultural tour, where you can explore ancient temples, palaces, and monasteries and immerse yourself in the local way of life. ",
    image:"./photos/tour/curtural.jpg"
},
    {
    id:3,
    title:"Day Tour",
    disc:" Experience the magic of Nepal with our day tour, where you can visit the country's top attractions and immerse yourself in its unique culture in just one day.",
    image:"./photos/tour/daytour.jpg"
},
    {
    id:4,
    title:"Jungle Safari",
    disc:"  Experience the wild side of Nepal with our safari tour, where you can embark on an unforgettable journey through pristine wilderness, encounter rare wildlife, and make memories to last a lifetime.",
    image:"./photos/tour/jungleSafari.jpg"
},
    {
    id:5,
    title:"Adventure Tour In Nepal",
    disc:"  Challenge yourself with our adventure tour in Nepal, where you can explore rugged terrain, conquer mighty mountains, and soak in the stunning landscapes while creating unforgettable experiences that will stay with you forever.",
    image:"./photos/tour/adventure.jpg"
},
    {
    id:6,
    title:"Hiking Tour In Nepal",
    disc:"  Unleash your inner adventurer with our hiking tour in Nepal, where you can trek through scenic trails, witness breathtaking vistas, and immerse yourself in the local culture while creating lifelong memories.",
    image:"./photos/tour/hiking.jpg"
},
]
export default Data;