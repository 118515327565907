import React from "react";
import "./blog.css";
// import BlogData from "./BlogApi.js";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const history = useNavigate();

  const handlehoneymoon = () => {
    history("/blog/nepal-the-best-honeymoon-destination-in-the-world.html");
  };
  const handlemustang = () => {
    history("/blog/mustang-a-hidden-kingdom-of-nepal");
  };
  const handlealtsick = () => {
    history("/blog/how-to-avoid-altitude-sickness-in-nepal-trekking");
  };
  const handleeverest = () => {
    history("/blog/why-trekking-in-everest-region.html");
  };

  return (
    <>
      {/* <h1 className="blog-heading">Our Blog</h1>

      <div className="mainblogbox">
        {BlogData.map((curelm) => {
          const { image, title, disc, link } = curelm;
          return (
            <>
              <ul className="blog-mainbox">
                <li className="blog-items">
                  <div className="blogImgBox">
                    <a href={link}>
                      <img className="blogImg" src={image} alt=""onClick={handleclick}/>
                    </a>
                  </div>
                  <div className="disc-box">
                    <h2 className="blog-title">{title}</h2>
                    <p>{disc}</p>
                    <a href={link}>
                      <button className="blog-readmore">READ MORE</button>
                    </a>
                  </div>
                </li>
              </ul>
              <hr className="blogHr" />
            </>
          );
        })}
      </div>
 */}

      <h1 className="blog-heading">Our Blog</h1>

      <div className="mainblogbox">
        <ul className="blog-mainbox">
          <li className="blog-items">
            <div className="blogImgBox">
              <img
                className="blogImg"
                src="./photos/blog/wed.jpg"
                alt=""
                onClick={handlehoneymoon}
              />
            </div>
            <div className="disc-box">
              <h2 className="blog-title">
                The Best Honeymoon Destination in the World
              </h2>
              <p>
                Nepal is undoubtedly one of the best honeymoon destinations in
                the world. The country's breathtaking natural beauty, rich
                cultural heritage, and hospitable people make it an ideal place
                for newlyweds to start their journey together. Nepal offers an
                array of romantic experiences, including trekking in the
                Himalayas, exploring ancient temples and monasteries, and
                taking....
              </p>
              <button className="blog-readmore" onClick={handlehoneymoon}>
                READ MORE
              </button>
            </div>
          </li>
          <hr className="blogHr" />

          <li className="blog-items">
            <div className="blogImgBox">
              <img
                className="blogImg"
                src="./photos/blog/mustang.jpg"
                alt=""
                onClick={handlemustang}
              />
            </div>
            <div className="disc-box">
              <h2 className="blog-title">Mustang: A Hidden Kingdom of Nepal</h2>
              <p>
                Nepal is a country filled with natural beauty and cultural
                richness, and one of its hidden gems is Mustang. Nestled in the
                Himalayan Mountains and bordering Tibet, Mustang offers a unique
                and unparalleled experience for those seeking an
                off-the-beaten-path adventure. The region is known for its stark
                landscapes, ancient monasteries, and the incredible....
              </p>
              <button className="blog-readmore" onClick={handlemustang}>
                READ MORE
              </button>
            </div>
          </li>
          <hr className="blogHr" />
          <li className="blog-items">
            <div className="blogImgBox">
              <img
                className="blogImg"
                src="./photos/blog/altitude-sickness.jpg"
                alt=""
                onClick={handlealtsick}
              />
            </div>
            <div className="disc-box">
              <h2 className="blog-title">
                Nepal: How to avoid altitude sickness in Nepal Trekking
              </h2>
              <p>
                Nepal is known for its picturesque trekking trails in the
                Himalayas. With the allure of snow-capped mountains and
                beautiful landscapes, trekking in Nepal is an adventure of a
                lifetime. However, trekking in higher altitudes can pose a risk
                of altitude sickness, which can be a serious condition if not
                treated properly. Here are some tips on how to avoid altitude
                sickness during your Nepal trekking....
              </p>
              <button className="blog-readmore" onClick={handlealtsick}>
                READ MORE
              </button>
            </div>
          </li>
          <hr className="blogHr" />
          <li className="blog-items">
            <div className="blogImgBox">
              <img
                className="blogImg"
                src="./photos/blog/whyEverest.jpg"
                alt=""
                // onClick={handleeverest}
              />
            </div>
            <div className="disc-box">
              <h2 className="blog-title">
                Nepal: Why Trekking in Everest Region
              </h2>
              <p>
                The Everest Region is a dream destination for every adventure
                enthusiast and nature lover. Trekking in this region is an
                experience of a lifetime that combines the thrill of adventure
                with breathtaking views of the world's highest mountains. But
                what makes the Everest Region so special? Here are some unique
                reasons to choose Everest Region trekking for your....
              </p>
              <button className="blog-readmore" onClick={handleeverest}>
                READ MORE
              </button>
            </div>
          </li>
          <hr className="blogHr" />
        </ul>
      </div>
    </>
  );
};

export default Blog;
