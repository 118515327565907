import React, { useState } from "react";
import axios from "axios";
import "./contact.css";
import { useRef } from "react";
import {
  FaFacebook,
  FaTwitterSquare,
  FaInstagramSquare,
  FaYoutube,
} from "react-icons/fa";


const Contact = () => {
  const [name, setname] = useState({
    fname: "",
    number: "",
    email: "",
    something: "",
  });
  const [message, setMessage] = useState("");
  let numberRef=useRef()
  let messageRef=useRef()
  let fnameRef=useRef()
  let emailRef=useRef()
  
  const submited = (e) => {
    setname("");
    emailRef.current.value="";
    fnameRef.current.value="";
    numberRef.current.value="";
    messageRef.current.value="";
    setMessage("✔ Message sent successfully");

      // for exios // 
    e.preventDefault()
    axios.post(
      'https://642a36da00dfa3b547417213.mockapi.io/crud',
      {
        name:name.fname,
        email:name.email,
        number:name.number,
        message:name.something,
      })
      setTimeout(() => {
        setMessage("");
      }, 2000);
  };

  const addevent = (event) => {
    // setname(event.target.value)
    const { name, value } = event.target;

    setname((preValue) => {
      if (name === "fname") {
        return {
          fname: value,
          number: preValue.number,
          email: preValue.email,
          something: preValue.something,
        };
      } else if (name === "number") {
        return {
          fname: preValue.fname,
          number: value,
          email: preValue.email,
          something: preValue.something,
        };
      } else if (name === "email") {
        return {
          fname: preValue.fname,
          number: preValue.number,
          email: value,
          something: preValue.something,
        };
      } else if (name === "something") {
        return {
          fname: preValue.fname,
          number: preValue.number,
          email: preValue.email,
          something: value,
        };
      }
    });
  };  

  return (
    <>
      <div className="contact-img">
        <h1>GET IN TOUCH</h1>
        <ul>
          <li>Phone: 98751231155</li>
          <li>Email: info.bridewaytravels@gmail.com</li>
          <li>Address: Santinagar,Kathmandu,Nepal</li>
        </ul>
      </div>
      <div className="contact-box">

      
        {/* Contact-left */}

        <div className="contact-left">
          <iframe className="iframe"
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20928.44246849389!2d85.33347805131176!3d27.699057151682325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1679219453050!5m2!1sen!2snp"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="folow-links">
            <h5 className="follow">Follow us on</h5>
            <div className="links">
              <a href="https://www.facebook.com/groups/697854915358591/" target="_blank" rel="noreferrer"> <FaFacebook className="common-links facebook"/>  </a>
              <a href="https://www.twitter.com" target="_blank" rel="noreferrer"><FaTwitterSquare className="common-links twitter" /></a>
              <a href="https:/www.instagram.com" target="_blank" rel="noreferrer"> <FaInstagramSquare className="common-links instagram" /></a>
              <a href="https://www.youtube.com" target="_blank" rel="noreferrer"><FaYoutube className="common-links youtube"  /></a>
            </div>
          </div>
        </div>

        {/* contact-right */}

        <div className="contact-right">
          <h1>Contact Us</h1>
          <form className="inputItems">
            <input
              type="text"
              placeholder="Enter Your Full Name"
              name="fname"
              value={name.fname}
              onChange={addevent}
              ref={fnameRef}
            />
            <input
              type="number"
              placeholder="Enter Phone Number"
              name="number"
              value={name.number}
              onChange={addevent}
              ref={numberRef}
            />
            <input
              type="email"
              placeholder="Enter Your Email"
              name="email"
              value={name.email}
              onChange={addevent}
              ref={emailRef}
            />
            <input
              className="messageBox"
              type="text"
              placeholder="Write Something"
              name="something"
              value={name.something}
              onChange={addevent}
              ref={messageRef}
            />
          </form>
          <button className="submit" onClick={submited}>
            Send Message
          </button>
          <h3 className="contact-message"> {message}</h3>
        </div>
      </div>
    </>
  );
};

export default Contact;
