import Top from "./top/Top";
import Nav from "./navigation/Nav";
import "./App.css";
import "./index.css";
import Home from "./home post/Home";
import About from "./about/About";
import Contact from "./contact/Contact";
import Error from "./Error";
import { Route, Routes } from "react-router-dom";
import Footer from "./footer/Footer";
import Tour from "./spe links/tour/Tour";
import Trekking from "./spe links/trekking/Trekking";
import Flightpage from "./spe links/Flight-Page/Flightpage";
import Blog from "./blog/Blog";
import Honeymoon from "./blog/Blog-Details/Honeymoon";
import Mustang from "./blog/Blog-Details/Mustang";
import AltitudeSickness from "./blog/Blog-Details/AltitudeSickness";
import WhyEverest from "./blog/Blog-Details/WhyEverest";


function App() {
  return (
    <>
      <Top />
      <Nav />
      <Routes>
        <Route exact path="/contact" Component={Contact} />
        <Route exact path="/about" Component={About} />
        <Route exact path="/" Component={Home}/>
        <Route exact path="/blog" Component={Blog}/>
        {/* <Route exact path="/read" Component={Read}/> */}
        <Route exact path="/blog/why-trekking-in-everest-region.html" Component={WhyEverest}/>
        <Route exact path="/blog/nepal-the-best-honeymoon-destination-in-the-world.html" Component={Honeymoon}/>
        <Route exact path="/blog/mustang-a-hidden-kingdom-of-nepal" Component={Mustang}/>
        <Route exact path="/blog/how-to-avoid-altitude-sickness-in-nepal-trekking" Component={AltitudeSickness}/>
        <Route exact path="/trekking" Component={Trekking}/>
        <Route exact path="/flight-booking" Component={Flightpage}/>
        <Route exact path="/tour" Component={Tour}/>
        <Route Component={Error} />
      </Routes>
      <Footer />
      
    </>
  );
}

export default App;
