import "./tour.css";
import Data from "./TourdataApi.js";

const Tour = () => {
  return (
    <>
      <div className="tour-container">
        <h1 className="heading">
          AFFORDABLE NEPAL TOUR PACKAGE, NEPAL HOLIDAY PACKAGES AT BEST PRICE
        </h1>
      </div>
      <div className="full-box">
        <div className="tour-disc">
          <h1>Adventure and Leisure Holiday in Nepal</h1>
          <p>
          Nepal is a destination that offers a unique combination of adventure and leisure holiday experiences. The country is home to the world's highest peak, Mount Everest, and offers an excellent opportunity for trekking, mountaineering, and other outdoor activities. Additionally, Nepal has a rich cultural heritage, with ancient temples, palaces, and monasteries scattered throughout the country. Visitors can enjoy yoga retreats, spa treatments, or simply relax and take in the breathtaking natural landscapes. Whether you're seeking thrills or looking for a peaceful retreat, Nepal is the perfect destination for an adventure and leisure holiday. At Bridge-Way Travels and Tours, we specialize in creating personalized travel packages that cater to your interests and preferences. Let us help you plan your dream adventure and leisure holiday in Nepal today.
          </p>
          <h1>
            AFFORDABLE NEPAL TOUR PACKAGE, NEPAL HOLIDAY PACKAGES AT BEST PRICE
          </h1>
        </div>

        <div className="tour-packages">
          {
            Data.map((curelm) => {
            const { title, disc, image } = curelm;
            return (
              <>
                <div className="tour-img-box">
                  <img src={image} alt="" />
                  <h1 className="tour-img-title"> {title}</h1>
                  <p className="tour-img-disc">{disc}</p>
                  <hr className="underline" />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Tour;
