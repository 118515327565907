import React from "react";
import "./flightpage.css";
// import Data from "./FlightApi.js";

const Flightpage = () => {
  return (
    <>
      <div className="flight-picture"></div>
      <div className="flight-container">
        <p className="flight-disc">
          Looking for the
          <span> cheapest air ticket agency in kathmandu Nepal, </span>
          at Bridge-Way Travels and Tours, we understand the importance of
          stress-free and affordable flight booking for our clients. Our online
          flight booking platform allows you to easily compare prices, airlines,
          and schedules to find the perfect flight for your needs. With just a
          few clicks, you can book your flight and receive instant confirmation,
          making your travel planning process seamless and convenient. <br />
          <br />
          We also offer the option of booking flights with our experienced
          travel agents who are available to assist you every step of the way.
          Our agents have access to exclusive deals and promotions, allowing us
          to provide you with the best possible flight options at the most
          competitive prices. Whether you're booking a domestic or international
          flight, we guarantee a hassle-free and enjoyable booking experience
          with Bridge-Way Travels and Tours.
        </p>
        <h2>Best Domestic & International Flight Ticket Agency in Nepal</h2>
        <p className="flight-disc">
          Bridge-Way Travels and Tours is the best domestic and international
          flight ticket agency in Nepal. We provide our clients with the most
          affordable and convenient flight options for both domestic and
          international destinations. With a user-friendly online booking system
          and a team of experienced travel experts, we make flight ticketing
          hassle-free and efficient. We are committed to providing our customers
          with the best service and ensuring a comfortable and safe journey.
        </p>
        <h2>Why Book Flight Ticket with Bridgeway Travels ?</h2>

        {/* {Data.map((curelm) => {
          const { heading, disc } = curelm;
          return (
            <>
              <ul>
                <li className="lists">
                  <h4>{heading}</h4>
                  <p className="listt">{disc}</p>
                </li>
              </ul>
            </>
          );
        })} */}
        <p>
        Bridgeway Travels is a trusted and reliable travel agency that provides top-notch services to its customers. When it comes to booking flight tickets, Bridgeway Travels offers several benefits that make it the best option for travelers in Nepal. <br /><br />

Firstly, Bridgeway Travels offers a wide range of domestic and international flight options at competitive prices. This means that customers can choose from a variety of airlines and flight times to suit their needs and budget. <br /><br />

In addition, Bridgeway Travels has a team of experienced travel experts who are available 24/7 to assist customers with their flight bookings. They can provide advice on the best flights, routes, and airlines to take, as well as any visa or travel requirements. <br /> <br />

Lastly, Bridgeway Travels uses advanced technology to ensure a hassle-free booking experience for customers. Customers can book their flights online through Bridgeway Travels' user-friendly website, or by contacting their customer service team directly. With Bridgeway Travels, customers can be assured of a seamless and stress-free flight booking process.





        </p>
      </div>
    </>
  );
};

export default Flightpage;
