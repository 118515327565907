import React, { useState } from "react";
import axios from "axios";
import { useRef } from "react";
import "./footer.css";
import {
  FaFacebook,
  FaTwitterSquare,
  FaInstagramSquare,
  FaYoutube,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";

const Footer = () => {
  const [data, setData] = useState({
    number: "",
    message: "",
  });

  const [message, setMessage] = useState("");

  let numberRef = useRef();
  let messageRef = useRef();

  const SendClicked = (e) => {
    setData("");
    setMessage("✔ Message sent successfully");
    setTimeout(() => {
      setMessage("");
    }, 2000);

    // for exios //
    e.preventDefault();
    axios.post("https://642a36da00dfa3b547417213.mockapi.io/crud", {
      number: data.number,
      message: data.message,
    });
    numberRef.current.value = "";
    messageRef.current.value = "";
  };

  const inputEvent = (event) => {
    const { name, value } = event.target;

    setData((curelm) => {
      if (name === "number") {
        return { ...curelm, number: value };
      } else if (name === "message") {
        return { ...curelm, message: value };
      }
    });
  };

  return (
    <>
      <div className="container">
        <div className="all-Contailers">
          {/* about */}

          <div className="aboutUs common">
            <h2 className="footerHeading">ABOUT US</h2>
            <p>
              Bridge-Way Travels and Tours is a travel agency in Nepal offering
              a wide range of travel services, including trekking, adventure
              tours, hotel reservations, and flight bookings. Our experienced
              team of travel professionals is dedicated to providing the best
              possible travel experience to our clients.
            </p>

            <div className="links">
              <a
                href="https://www.facebook.com/groups/697854915358591/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <FaFacebook className="common-links facebook" />{" "}
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitterSquare className="common-links twitter" />
              </a>
              <a
                href="https:/www.instagram.com"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <FaInstagramSquare className="common-links instagram" />
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="common-links youtube" />
              </a>
            </div>
          </div>

          {/* Address */}

          <div className="address common">
            <h2 className="footerHeading">ADDRESS</h2>
            <li>
              <FaMapMarkerAlt className="icons" />
              Santinagar,Kathmandu
            </li>
            <li>
              <FaPhoneAlt className="icons" />
              9863043308
            </li>
            <li>
              <FaEnvelope className="icons" />
              www.bridgeway.com
            </li>
          </div>

          {/* contact us */}

          <div className="contact common">
            <h2 className="footerHeading">CONTACT US</h2>
            <p>Number *</p>
            <input
              type="number"
              name="number"
              value={data.number}
              onChange={inputEvent}
              ref={numberRef}
            />
            <p>Message *</p>
            <input
              type="text"
              name="message"
              value={data.message}
              onChange={inputEvent}
              ref={messageRef}
            />
            <button className="send" onClick={SendClicked}>
              Send
            </button>
            <h3 className="messsage"> {message}</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
