const Data=[
    {
    id:1,
    title:"Everest Trekking Region",
    disc:" The Everest trekking region in Nepal is a breathtaking destination that offers a fascinating blend of natural wonders and cultural traditions, making it a dream come true for adventure seekers.",
    image:"./photos/trek/everest.jpg"
},
    {
    id:2,
    title:"Kanchenjunga Trekking",
    disc:"  Kanchenjunga Trekking is an off-the-beaten-path adventure in Nepal that takes you through remote villages, lush forests, and stunning mountain vistas, providing an unforgettable trekking experience.",
    image:"./photos/trek/kanchenjunga.jpg"
},
    {
    id:3,
    title:"Manaslu Trekking Region",
    disc:"  The Manaslu trekking region offers stunning views of Mount Manaslu, the eighth highest mountain in the world, and a unique cultural experience with the ethnic groups living along the trail.",
    image:"./photos/trek/manaslu.jpg"
},
    {
    id:4,
    title:"Annapurna Trekking Region",
    disc:"  The Annapurna Trekking Region is a paradise for trekkers with diverse landscapes, cultures, and trekking routes. It offers both challenging and easy treks, stunning mountain views, and unique experiences.",
    image:"./photos/trek/annapurna.jpg"
},
    {
    id:5,
    title:"Langtang Trekking Region",
    disc:"  Experience the unique blend of Tibetan and Nepalese culture while trekking through the picturesque Langtang valley. Discover the region's diverse flora and fauna, and take in stunning views of snow-capped mountains.",
    image:"./photos/trek/lantang.jpg"
},
    {
    id:6,
    title:"Dolpo Trekking",
    disc:" Dolpo Trekking takes you on an off-the-beaten-path adventure through the mesmerizing landscapes and hidden valleys of Nepal's western region, with unique insights into the traditional Bonpo culture and lifestyle.",
    image:"./photos/trek/dolpo.jpg"
},
]
export default Data;