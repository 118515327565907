import React from "react";
import "./top.css";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

const Top = () => {
  return (
    <>
      <div className="main-heading">
        <div className="heading">
          <div className="heading-left">
            <span>
              <FaMapMarkerAlt color="gold" className="FaMapMarkerAlt" />
            </span>
            <h4 className="location">SANTINAGAR,KATHMANDU,NEPAL</h4>
          </div>
          <div className="heading-right">
            <span>
              <FaPhoneAlt color="gold" className="FaPhoneAlt" />
            </span>
            <h4 className="contact-number">
              +977 14584564(Office), 9863043308, 9840610659 (Mobile)
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Top;
