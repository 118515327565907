import React from "react";
import "./about.css";

const About = () => {
  return (
    <>
      <img className="image" src="./photos/travel.jpg" alt="" />
      <h2 className="headingg">About Us</h2>
      <div className="aboutUs-disc">
        <h5 className="discc">
          Bridge-Way Travels and Tours is a premier travel company that
          specializes in creating exceptional travel experiences. With years of
          experience in the travel industry, we offer a wide range of
          personalized travel packages that cater to different preferences and
          interests. Contact us today to plan your next unforgettable adventure.
        </h5>
        {/* Services */}
        <div className="servicebox commonBox">
          <h3 className="titles">Our Services</h3>
          <li>
            <h4 className="subtitle"> flight Ticketing:</h4>
            <p>
            At Bridge-Way Travels and Tours, we offer comprehensive flight ticketing services that make air travel hassle-free. Our experienced travel professionals help you find the best flights based on your budget, preferences, and travel schedule. We handle everything from booking to confirmation, ensuring a smooth and stress-free travel experience.
            </p>
          </li>
          <li>
            <h4 className="subtitle"> Adventures and Toures:</h4>
            <p>
            At Bridge-Way Travels and Tours, we specialize in creating unique and unforgettable adventure and tour packages that cater to your interests and preferences. From thrilling outdoor activities to cultural immersions and luxury getaways, we have the expertise and resources to plan the perfect adventure or tour for you.
            </p>
          </li>
          <li>
            <h4 className="subtitle"> Hotel Reservation:</h4>
            <p>
            Bridge-Way Travels and Tours offers comprehensive hotel reservation services, ensuring that you have a comfortable and enjoyable stay wherever you go. With our strong partnerships with top hotels worldwide, we provide access to exclusive deals and discounts, making sure you get the best value for your money. Let us handle your hotel reservations and make your trip stress-free.
            </p>
          </li>
        </div>
        {/* features */}

        <div className="features-box commonBox">
          <h3 className="titles">Feature of Bridge-Way Travels and Tours</h3>
          <li>
            <h4 className="subtitle"> Online Reservation System:</h4>
            <p>
            Our online reservation system at Bridge-Way Travels and Tours provides a convenient and efficient way to book your travel arrangements from the comfort of your own home. Our user-friendly interface allows you to browse and select the best travel options for your budget and preferences, making planning your next trip easy and hassle-free.
            </p>
          </li>
          <li>
            <h4 className="subtitle"> Experienced Staff:</h4>
            <p>
            At Bridge-Way Travels and Tours, we pride ourselves on having a team of experienced and knowledgeable travel professionals who are dedicated to providing exceptional customer service. With years of experience in the travel industry, our staff has the expertise and resources to create personalized travel experiences that exceed your expectations. Trust us to make your travel dreams a reality.
            </p>
          </li>
          <li>
            <h4 className="subtitle"> Service:</h4>
            <p>
            At Bridge-Way Travels and Tours, we offer a wide range of services to make your travel experience unforgettable. From personalized travel packages to comprehensive flight ticketing and hotel reservation services, we have the expertise and resources to create the perfect trip for you. Trust us to handle all your travel needs and make your journey stress-free.
            </p>
          </li>
          <li>
            <h4 className="subtitle"> Price:</h4>
            <p>
            At Bridge-Way Travels and Tours, we believe that exceptional travel experiences don't have to come at a high price. We work hard to provide our clients with competitive prices and exclusive deals that ensure they get the best value for their money. Trust us to create personalized travel experiences that won't break the bank.
            </p>
          </li>
        </div>

        {/* profile */}

        <div className="frofile-box commonBox">
          <h3 className="titles">Company Profile</h3>
          <li>
            <h4 className="subtitle"> Type of Business:</h4>
            <p>Travel Agency and Tours Operators</p>
          </li>
          <li>
            <h4 className="subtitle"> Physical Address:</h4>
            <p>
              Baneshwor Santinagar, <br />
              Kathmandu Nepal
            </p>
          </li>
          <li>
            <h4 className="subtitle"> Contact Phone & Email (Head office):</h4>
            <p>
              Telephone: 9863043308 <br />
              Email:info.bridewaytravels@gmail.com
            </p>
          </li>
        </div>
      </div>
    </>
  );
};

export default About;
