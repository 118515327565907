import "./trekking.css";
import Data from "./TrekkingApi.js";

const Trekking = () => {
  return (
    <>
      <div className="trek-container">
        <h1 className="heading">
          TREKKING IN NEPAL | BOOK NEPAL TREKKING PACKAGE WITH LOCAL EXPERT
        </h1>
      </div>
      <div className="full-box">
        <div className="trek-disc">
          <p>
            <span>Trekking in Nepal</span> is a one-of-a-kind adventure that offers
            breathtaking scenery, unique cultures, and challenging trails. With
            eight of the world's highest peaks, Nepal is the ultimate
            destination for adventure seekers. From the remote Dolpo region to
            the iconic Everest Base Camp, the possibilities for trekking in
            Nepal are endless. But it's not just the mountains that make Nepal a
            fascinating destination for trekking. The diverse landscape includes
            lush forests, picturesque valleys, and tranquil lakes that offer a
            peaceful contrast to the rugged terrain. <br />
            <br />
            <span>Trekking</span> in Nepal is also an opportunity to immerse oneself in the
            local culture. The Nepalese people are known for their warm
            hospitality and rich cultural heritage. Along the trails, trekkers
            can visit traditional villages, witness colorful festivals, and
            experience authentic Nepalese cuisine. Trekking in Nepal is not just
            a physical challenge but also an opportunity to broaden one's
            horizons and gain a deeper understanding of a unique culture.
          </p>
          <h1>
            TREKKING IN NEPAL | BOOK NEPAL TREKKING PACKAGE WITH LOCAL EXPERT
          </h1>
        </div>

        <div className="trek-packages">
          {Data.map((curelm) => {
            const { title, disc, image } = curelm;
            return (
              <>
                <div className="trek-img-box">
                  <img src={image} alt="" />
                  <h1 className="trek-img-title">{title}</h1>
                  <p className="trek-img-disc">{disc}</p>
                  <hr className="underline" />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Trekking;
