import React from "react";
import "./home.css";
import { FaPhoneAlt } from "react-icons/fa";
// import Speci from "../specifications/Speci";
// import "./speci.css";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const history = useNavigate();

  const handleTour = () => {
    history("/tour");
  };
  const handletrek = () => {
    history("/trekking");
  };
  const handleflight = () => {
    history("/flight-booking");
  };

  return (
    <>
      <div className="imgg">
        <img className="hero-img" src="./photos/nepal-airlines.jpg" alt="" />
      </div>
      {/* <div className="special-disc"></div> */}
      <div className="about-bidgeway">
        <h1>Welcome to Bridge-Way Travels and Tours</h1>
        <p className="about-bridgeway-disc">
          Welcome to <span>Bridge-Way Travels and Tours!</span>  Our team is dedicated to
          creating unforgettable travel experiences tailored to your unique
          needs and preferences, ensuring that every aspect of your journey is seamless and stress-free.We can't wait to work with you to plan your next
          travel adventure!
        </p>
      </div>
      <div className="why-chooose">
        <h2>Why Choose Bridge-Way Travel & Tour</h2>
        <div className="mainBox">
          <ul>
            <li className="sub-box first-box">
              <h3>Why Travel With Us</h3>
              <li>Best price Gurantee</li>
              <li>Easy Booking System</li>
              <li>99% Satisfied Customers</li>
              <li>24/7 Customer Care</li>
            </li>
            <li className="sub-box second-box">
              <h3>Flight Booking</h3>
              <li>International Flights</li>
              <li>Domestic Flights</li>
              <li>Low Cost Airlines</li>
              <li>Cheap Flight Booking</li>
              <li>Cheap Air Tickets</li>
            </li>
            <li className="sub-box third-box">
              <h3>Need Help?</h3>
              <h4>Call us:</h4>
              <div className="phNumber">
                <FaPhoneAlt className="FaPhoneAltt" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* <Speci/> */}
      {/* specialities */}

      <div className="specialities">
        <h1 className="specialities-heading"> Our Specialities</h1>
        <div className="special-Box">
          <li className="box">
            <img src="./photos/tour.jpg" alt="" onClick={handleTour} />
            <h2>TOUR IN NEPAL</h2>
          </li>
          <li className="box">
            <img src="./photos/trekking.jpg" alt="" onClick={handletrek} />
            <h2>TREKKING IN NEPAL</h2>
          </li>
          <li className="box">
            <img src="./photos/flight.jpg" alt="" onClick={handleflight} />
            <h2>FLIGHT BOOKING</h2>
          </li>
        </div>
      </div>
    </>
  );
};

export default Home;
