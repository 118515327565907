import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {FaBars} from "react-icons/fa";
import "./nav.css";


const Nav = () => {

  const [menulists,setMenulists]=useState(false)
  const toggleBtn=()=>{
    setMenulists(!menulists) 

  }

  return (
    <div className="mainnav">
      <div className="nav">
        <div className="travel-name">BRIDGEWAY TRAVELS</div>
        <div className="navlinks">
          <ul>
            <NavLink activeclassname="active-Class" to="/" className="navLists">Home</NavLink>
            <NavLink activeclassname="activeClass" to="/about" className="navLists">About Us</NavLink>
            <NavLink to="/tour" className="navLists">Tours</NavLink>
            <NavLink to="/blog" className="navLists">Blog</NavLink>
            <NavLink to="/contact" className="navLists">Contact Us</NavLink>
          </ul>
        </div>
          <FaBars className="toggle-btn" onClick={toggleBtn}/> 
      </div>


      <div className= {menulists?"drop_Down open":"drop_Down" }>
            <NavLink activeclassname="active-Class" to="/" className="navLists">Home</NavLink>
            <NavLink activeclassname="activeClass" to="/about" className="navLists">About Us</NavLink>
            <NavLink to="/tour" className="navLists">Tours</NavLink>
            <NavLink to="/blog" className="navLists">Blog</NavLink>
            <NavLink to="/contact" className="navLists">Contact Us</NavLink>
      </div>
    </div>
    
  );
};

export default Nav;
